<template>
</template>

<script>
export default {
    name: 'Dashboard'
}
</script>

<style>

</style>